import React, { useState, useEffect } from 'react';
import axios from './APIAxios';
import Autocomplete from '@mui/material/Autocomplete';
import { Stack, TextField, Typography, Box, Radio, RadioGroup, FormControlLabel, TextareaAutosize, Button, useTheme, CircularProgress } from '@mui/material';
import { validateName, validateEmail, validateCompany, validateSurveySelection, validateTextArea, validateSelectedCategory } from './FormValidation';
import useMediaQuery from '@mui/material/useMediaQuery';
import Popup from './MUIPopup';
import './Model.css';

const MuiInputFields = () => {
  const [selectedSurvey, setSelectedSurvey] = useState<{ id: string; name: string } | null>(null);
  const [selectedSurveyId, setSelectedSurveyId] = useState<string | null>(null);
  const [categoryType, setCategoryType] = useState('');
  const [categoryOptions, setCategoryOptions] = useState<{ id: string; name: string }[]>([]);
  const [modificationDescription, setModificationDescription] = useState('');
  const [additionalDescription, setAdditionalDescription] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<{ id: string; name: string } | null>(null);
  const [myData, setMySurveyData] = useState<{ id: string; name: string }[]>([]);
  const [firstName, setFirstName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [surveyError, setSurveyError] = useState<string>('');
  const [isModificationFocused, setIsModificationFocused] = useState(false);
  const [isAdditionalFocused, setIsAdditionalFocused] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [radioValue, setRadioValue] = useState('');
  




  // Fetch survey data from API
  const getSurveyData = async () => {
    try {
      const res = await axios.get("/surf/GetSurvey");
      setMySurveyData(res.data.data); // Store survey data in state
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getSurveyData(); // Fetch data when component mounts
  }, []);

  // Fetch category options based on selected survey ID
  const fetchCategoryOptions = async (surveyId: string) => {
    try {
      const res = await axios.get(`/surf/GetCategory?surveyId=${surveyId}`);
      setCategoryOptions(res.data.data);
    } catch (error) {
      console.error("Error fetching category options:", error);
      setCategoryOptions([]); 
    }
  };
  useEffect(() => {
    if (selectedSurveyId) {
      fetchCategoryOptions(selectedSurveyId);
    }
  }, [selectedSurveyId]);

 
   // Handler for radio button change
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setRadioValue(value); // Set the radio value
    setShowDropdown(value === 'Existing category');
    setSelectedCategory(null); 
    setCategoryType(value);
    setErrors({}); 
  };

  // Handler for survey selection change
  const handleSurveySelectionChange = (event: React.ChangeEvent<{}>, value: { id: string; name: string } | null) => {
    setSelectedSurvey(value);
    setSelectedSurveyId(value?.id || null); 
  };

  // Handler for category selection change
  const handleCategorySelectionChange = (event: React.ChangeEvent<{}>, value: { id: string; name: string } | null) => {
    setSelectedCategory(value); 
  };

  // Handler for Textareas 
  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setModificationDescription(event.target.value);
  };

  const handleAdditionalDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setAdditionalDescription(event.target.value);
  };

  const handleModificationFocus = () => {
    setIsModificationFocused(true);
  };

  const handleModificationBlur = () => {
    setIsModificationFocused(false);
  };

  const handleAdditionalFocus = () => {
    setIsAdditionalFocused(true);
  };

  const handleAdditionalBlur = () => {
    setIsAdditionalFocused(false);
  };

  

  //Form Validation
  const validateForm = () => {
    let isValid = true;
    const newErrors: { [key: string]: string } = {};

    // Validate first and last name
    const nameError = validateName(firstName);
    if (nameError) {
      newErrors.firstName = nameError;
      isValid = false;
    }

    // Validate Company
    const companyError = validateCompany(company);
    if (companyError) {
      newErrors.company = companyError;
      isValid = false;
    }

    // Validate email
    const emailError = validateEmail(email);
    if (emailError) {
      newErrors.email = emailError;
      isValid = false;
    }

    // Validate survey selection
    const surveySelectionError = validateSurveySelection(selectedSurvey);
    if (surveySelectionError) {
      setSurveyError(surveySelectionError);
      isValid = false;
    } else {
      setSurveyError('');
    }
    
    //Validate for Radio Selection
    const categoryError = validateSelectedCategory(categoryType, selectedCategory);
  if (categoryError) {
    newErrors.selectedCategory = categoryError;
    isValid = false;
  }

    // Validate Modification Description
    const modificationDescriptionError = validateTextArea(modificationDescription, 'Description');
    if (modificationDescriptionError) {
      newErrors.modificationDescription = modificationDescriptionError;
      isValid = false;
    }

    // Validate Additional Description
    const additionalDescriptionError = validateTextArea(additionalDescription, ' the rationale behind your request');
    if (additionalDescriptionError) {
      newErrors.additionalDescription = additionalDescriptionError;
      isValid = false;
    }

    // Set errors state
    setErrors(newErrors);
    return isValid;
  };

  // Handle form submission
  const handleSubmit = async () => {
    const isValid = validateForm();

    if (isValid) {
      setIsLoading(true); 
      try {
          let formData: any;  
  
          // Prepare data object to send
          if (categoryType === "Existing category") {
              formData = {
                  "firstnamelastname": firstName,
                  "company": company,
                  "email": email,
                  "surveyid": selectedSurveyId,
                  "categoryid": selectedCategory ? selectedCategory.id : '',
                  "requestdescription": modificationDescription,
                  "rationalforrequest": additionalDescription,
                  "categorytype": categoryType,
              };
          } else if (categoryType === "New") {
              formData = {
                  "firstnamelastname": firstName,
                  "company": company,
                  "email": email,
                  "surveyid": selectedSurveyId,
                  "categoryname": selectedCategory ? selectedCategory.name : '',
                  "requestdescription": modificationDescription,
                  "rationalforrequest": additionalDescription,
                  "categorytype": categoryType,
              };
          } else {
              console.error('Unexpected categoryType:', categoryType);
              return;
          }
  
        // Send POST request
        const res = await axios.post('/surf/createsurfrequest', formData);

        // Handle successful submission
        console.log('Form submitted successfully:', res.data);
        // Show success popup
        setIsFormSubmitted(true);


         // Clear all fields after successful submission
      setFirstName('');
      setCompany('');
      setEmail('');
      setSelectedSurvey(null);
      setSelectedSurveyId(null);
      setCategoryType('');
      setModificationDescription('');
      setAdditionalDescription('');
      setShowDropdown(false);
      setSelectedCategory(null);
      setErrors({});
      setSurveyError('');
      setRadioValue('');

      } catch (error) {
        console.error('Error submitting form:', error);
      }
      finally {
        setIsLoading(false); // Set loading state back to false after request completes
      }
    } else {
      console.log('Form validation errors:', errors);
    }
  };

  // Function to scroll to the top of the page
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"  // Optional: smooth scrolling behavior
  });
};
// Effect hook to scroll to top when isFormSubmitted changes
useEffect(() => {
  if (isFormSubmitted) {
    scrollToTop();
  }
}, [isFormSubmitted]);


  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  
  return (
    <Stack spacing={6}>
      <Stack spacing={4}>
        <Typography variant='subtitle1' style={{ marginLeft: isSmallScreen ? 50 : 70 , marginTop: isSmallScreen ? 5 : 30  }} fontFamily="Lato" fontSize= '1.1rem'>Submitter information</Typography>
        <TextField label='First and last name' variant='outlined' color='secondary' style={{ width: isSmallScreen ? 300 : 500 , marginLeft: isSmallScreen ? 50 : 70, height: '30px' }} value={firstName} onChange={(e) => setFirstName(e.target.value)} inputProps={{ maxLength: 100 }} />
        {errors.firstName && <Typography color="error" fontFamily="Lato" fontSize= '0.9rem' style={{ width: '500px', marginLeft: isSmallScreen ? 50 : 70, height: '10px' }}>{errors.firstName}</Typography>} <br/>
        <TextField label='Company' variant='outlined'  color='secondary' style={{width: isSmallScreen ? 300 : 500, marginLeft: isSmallScreen ? 50 : 70, height: '30px' }} value={company} onChange={(e) => setCompany(e.target.value)} inputProps={{ maxLength: 100 }} />
        {errors.company && <Typography color="error" fontFamily="Lato" fontSize= '0.9rem' style={{ width: '500px', marginLeft: isSmallScreen ? 50 : 70, height: '10px' }}>{errors.company}</Typography>} <br/>
        <TextField label='Email Address' variant='outlined' color='secondary' style={{ width: isSmallScreen ? 300 : 500, marginLeft: isSmallScreen ? 50 : 70, height: '30px' }} value={email} onChange={(e) => setEmail(e.target.value)} inputProps={{ maxLength: 100 }} />
        {errors.email && <Typography color="error" fontFamily="Lato" fontSize= '0.9rem' style={{ width: '500px', marginLeft: isSmallScreen ? 50 : 70, height: '10px' }}>{errors.email}</Typography>} <br/>
      </Stack>

      {/* Survey Selection */}
      <Stack spacing={4}>
        <Typography variant='subtitle1' style={{ marginLeft: isSmallScreen ? 50 : 70, marginTop: '50px',marginRight: isSmallScreen ? 2 : 0 }} fontFamily="Lato" fontSize= '1.1rem'>Which survey would you like to request an update to?</Typography>
        <Box style={{ marginLeft: isSmallScreen ? 50 : 70, marginTop: '10px',width: isSmallScreen ? 300 : 500 }}>
        <Autocomplete
            options={myData}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField color='secondary' {...params} label="Select Survey" />}
            size='small'
            fullWidth
            value={selectedSurvey}
            onChange={handleSurveySelectionChange}
          />
        </Box>
        {surveyError && <Typography color="error" fontFamily="Lato" fontSize= '0.9rem' style={{ marginLeft: isSmallScreen ? 50 : 70, marginTop: '10px' }}>{surveyError}</Typography>}
      </Stack>

      {/* Category Modification */}
      <Stack spacing={2}>
        <Typography variant='subtitle1' style={{ marginLeft: isSmallScreen ? 50 : 70 }} fontFamily="Lato" fontSize= '1.1rem'>What would you like to modify?</Typography>
        <RadioGroup value={radioValue}  onChange={handleRadioChange}  style={{ marginLeft: isSmallScreen ? 50 : 70, marginTop: '10px' }} row>
          <FormControlLabel value="Existing category" control={<Radio color='secondary'/>} label="Existing category" />
          <FormControlLabel value="New" control={<Radio color='secondary' />} label="New category" />
        </RadioGroup>
        {/* Conditionally render dropdown or text field based on radio selection */}
        {showDropdown && (
          <Box style={{ marginLeft: isSmallScreen ? 50 : 70, marginTop: '10px',width: isSmallScreen ? 300 : 500 }}>
             <Autocomplete
              options={categoryOptions}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField color='secondary' {...params} label="Search or Select Category" />
              )}
              size='small'
              fullWidth
              value={selectedCategory}
              onChange={handleCategorySelectionChange}
            />
            {errors.selectedCategory && (
              <Typography color='error' fontFamily="Lato" fontSize= '0.9rem' style={{ width: '500px', height: '10px' }}>{errors.selectedCategory}</Typography>
            )}
          </Box>
        )}

        {!showDropdown && (
          <Box style={{ marginLeft: isSmallScreen ? 50 : 70, marginTop: '10px',width: isSmallScreen ? 300 : 500 }}>
            <TextField
              label='New Category'
              variant='outlined'
              size='small'
              color='secondary'
              fullWidth
              value={selectedCategory?.name || ''}
              onChange={(event) => setSelectedCategory({ id: '', name: event.target.value })}
              inputProps={{maxLength: 50}}
            />
            {errors.selectedCategory && (
              <Typography color='error' fontFamily="Lato" fontSize= '0.9rem' style={{ width: isSmallScreen ? 300 : 500, height: '10px' }}>{errors.selectedCategory}</Typography>
            )}
          </Box>
        )}
      </Stack>

      {/* Description */}
      <Stack spacing={4}>
        <Typography variant='subtitle1' style={{ marginLeft: isSmallScreen ? 50 : 70}} fontFamily="Lato" fontSize= '1.1rem'>Describe your request</Typography>
        <TextareaAutosize
          aria-label="modification description"
          minRows={10}
          style={{ width: isSmallScreen ? 280 : 500, marginLeft: isSmallScreen ? 50 : 70, marginTop: '10px', borderRadius: '8px', padding: '10px',
          border: `1px solid ${isModificationFocused ? '#800080' : '#ced4da'}`, outline: `1px solid ${isModificationFocused ? ' #800080' : 'transparent'}`, boxShadow: isModificationFocused ? '0 0 3px  #800080' : 'none' }}
          value={modificationDescription}
          onChange={handleDescriptionChange}
          onFocus={handleModificationFocus}
          onBlur={handleModificationBlur}
          maxLength={3000}
        />
        {errors.modificationDescription && <Typography color="error" fontFamily="Lato" fontSize= '0.9rem' style={{ marginLeft: isSmallScreen ? 50 : 70, marginTop: '10px' }}>{errors.modificationDescription}</Typography>}
      </Stack>

      {/* Rationale */}
      <Stack spacing={4}>
        <Typography variant='subtitle1' style={{ marginLeft: isSmallScreen ? 50 : 70}} fontFamily="Lato" fontSize= '1.1rem'>Provide the rationale behind your request</Typography>
        <TextareaAutosize
          aria-label="additional description"
          minRows={10}
          style={{ width: isSmallScreen ? 280 : 500, marginLeft: isSmallScreen ? 50 : 70, marginTop: '10px', borderRadius: '8px', padding: '10px', 
          border: `1px solid ${isAdditionalFocused ? ' #800080' : '#ced4da'}`, outline: `1px solid ${isAdditionalFocused ? ' #800080' : 'transparent'}`,boxShadow: isAdditionalFocused ? '0 0 3px  #800080' : 'none'}}
          value={additionalDescription}
          onChange={handleAdditionalDescriptionChange}
          onFocus={handleAdditionalFocus}
          onBlur={handleAdditionalBlur}
          maxLength={3000}
        />
        {errors.additionalDescription && <Typography color="error" fontFamily="Lato" fontSize= '0.9rem' style={{ marginLeft: isSmallScreen ? 50 : 70, marginTop: '10px' }}>{errors.additionalDescription}</Typography>}
      </Stack>

      {/* Instructions */}
      <Stack>
      <Typography
      variant='body1'
      sx={{
        marginLeft: isSmallScreen ? 7 : 8,
        marginTop: isSmallScreen ? 2 : 5,
        marginRight: isSmallScreen ? 3 : 50,
        marginBottom: isSmallScreen ? 5 : 0,
        fontFamily: 'Lato',
        fontSize:'1rem',
      }}
    >
      Instructions for sending additional files to support your request (optional) will be included in the confirmation email you receive after you click Submit.
    </Typography>
      </Stack>

      {/* Submit Button */}
      <Stack spacing={4}>
      <Button
        variant="contained"
        size="medium"
        onClick={handleSubmit}
        style={{ marginLeft: '60px', width: '80px', backgroundColor: '#5e2466', fontFamily: "Lato" }}
        disabled={isLoading} // Disable button when loading
      >
        {isLoading ? <CircularProgress size={24} color="info" /> : 'Submit'}
      </Button>
      <Box height={50} />
      </Stack>
      {/* Popup for Success Message */}
      <Popup open={isFormSubmitted} onClose={() => setIsFormSubmitted(false)} />
      {/* Loader centered on screen */}
      {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent white background
            zIndex: 9999, // Ensure loader is on top of other content
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className="dot-spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
            <div className="dot3"></div>
          </div>
        </div>
      )}
    </Stack>
  );
};

export default MuiInputFields;
