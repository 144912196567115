//import './App.css';
import React from 'react';
import  MuiInputFields  from './Component/MuiInputFields';
import { MuiNavbar } from './Component/MuiNavbar';
import  MuiBody  from './Component/MuiBody';
import  MuiBottomNavBar  from './Component/MuiBottomNavBar';

function App() {
  return (
    <div className="App">
      <MuiNavbar></MuiNavbar>
      <MuiBody></MuiBody>
      <MuiInputFields></MuiInputFields>
      <MuiBottomNavBar></MuiBottomNavBar>
    </div>
  );
}

export default App;
