import { Grid, Link, Typography } from '@mui/material';
import envVar from '../envVar.json'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const MuiBody = () => {

  const wave = envVar.wave;
  const preActivityDate = envVar.preActivityDate;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container spacing={2}>
      <Grid>
        <Typography variant='h3' marginLeft={8} marginTop={5} fontFamily="Lato" color={'#5e2466'} fontSize={'2.5rem'}>Survey Update Request Form</Typography><br />
        <Typography variant='subtitle1' marginLeft={8} marginTop={1} fontFamily="Lato" fontSize='1rem'>
          <Link href='https://mrisimmons.com' color="#5e2466" style={{ marginRight: '3px' }}>Home </Link>
          <Link href='https://mrisimmons.com' color="#5e2466" fontSize={'.8rem'} style={{ marginRight: '3px' }}> &gt; </Link>
          <Link href='https://www.mrisimmons.com/client-resources/' color="#5e2466" style={{ marginRight: '3px' }}>Client Resources </Link>
          <Link href='https://www.mrisimmons.com/client-resources/' color="#5e2466" fontSize={'.8rem'}>&gt;</Link>
        </Typography>
        <Typography variant='subtitle1' marginLeft={8} marginBottom={5} fontFamily="Lato" fontSize='1.1rem' >Survey Update Request</Typography>
        <hr />
        <>
          <Typography
            variant='body1'
            sx={{
              marginLeft: 8,
              marginTop: isSmallScreen ? 2 : 5,
              marginRight: isSmallScreen ? 3 : 50,
              marginBottom: isSmallScreen ? 10 : 0,
              fontFamily: 'Lato',
              fontSize: '1rem',
            }}
          >
            Use the change request forms below to submit your suggested additions, deletions, or modifications to any MRI-Simmons survey.
          </Typography>
          <Typography
            variant='body1'
            sx={{
              marginLeft: 8,
              marginTop: isSmallScreen ? 2 : 5,
              marginRight: isSmallScreen ? 3 : 50,
              marginBottom: isSmallScreen ? 10 : 0,
              fontFamily: 'Lato',
              fontSize: '1rem',
            }}
          >
            All requests will be considered and approved based on space limitations and the merit of market share data available to us. We recommend focusing on deletions as much as additions and that share of market information or other rationale accompany all requests. The support material you provide will remain confidential and be used only for this project. All requests must be ranked in order of priority.
          </Typography>
          <Typography
            variant='body1'
            sx={{
              marginLeft: 8,
              marginTop: isSmallScreen ? 2 : 5,
              marginRight: isSmallScreen ? 3 : 50,
              marginBottom: isSmallScreen ? 10 : 0,
              fontFamily: 'Lato',
              fontSize: '1rem',
            }}
          >
            Please note, the deadline for request submissions for {wave} fieldwork is {preActivityDate}. All requests received after that date will be considered for the following wave’s request submissions.
          </Typography>
        </>

      </Grid>
    </Grid>
  );
};

export default MuiBody;
