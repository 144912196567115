import React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
} from '@mui/material';
export const MuiNavbar = () => {
  return (
    <Box >
      <AppBar position='static' style={{ backgroundColor: '#5e2466'}}>
        <Toolbar >
          <IconButton edge='start' color='inherit' aria-label='menu'>
            <a href="https://mrisimmons.com">
              <img src="https://www.mrisimmons.com/wp-content/uploads/2019/08/MRI-Simmons_logo_horz_white.svg" alt='Logo' style={{ width: '180px', marginLeft: '50px' }} />
            </a>
          </IconButton>
        </Toolbar>
      </AppBar>
      </Box>
  );
};
