// Popup.tsx

import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  DialogActions,
  Button,
  Box,
} from '@mui/material';

interface PopupProps {
  open: boolean;
  onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} style={{fontFamily:"Lato" }}>
      <DialogTitle color={'white'} style={{fontFamily:"Lato",backgroundColor: 'purple'}}>Request has been submitted</DialogTitle>
      <DialogContent>
        <Typography style={{fontFamily:"Lato" }}>You will receive an email with the details of your request shortly.</Typography>
      </DialogContent>
      <DialogActions>
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Button
            onClick={onClose}
            size='small'
            variant="contained"
            color='secondary'
            style={{ fontFamily: 'Lato' }}
          >
            OK
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default Popup;

