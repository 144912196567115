  
  // Validation for Name
  export const validateName = (value: string): string | undefined => {
    if (!value.trim()) {
      return 'First and last name is required';
    }
    const regex = /[!@#$%^&*(),.?":{}|<>0-9]/;
    if (regex.test(value)) {
      return 'Name cannot contain special characters or numbers';
    }
    return undefined;
  };
  
  // Validation for Company
  export const validateCompany = (value: string): string | undefined => {
    if (!value.trim()) {
      return 'Company name is required';
    }
    return undefined;
  };

  // Validation for Email
  export const validateEmail = (value: string): string | undefined => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      return 'Invalid email address';
    }
    return undefined;
  };

  // Validation for Survey selection
  export const validateSurveySelection = (value: { id: string; name: string } | null): string | undefined => {
    if (!value) {
      return 'Please select a survey';
    }
    return undefined;
  };

  // Validation for Radio selection
  export const validateSelectedCategory = (categoryType: string | null,selectedCategory: { id: string; name: string } | null): string | undefined => {
    if (!categoryType) {
      return 'Please select a category type';
    }
    if (categoryType === 'Existing category') {
      if (!selectedCategory) {
        return 'Please select a category';
      }
    } else if (categoryType === 'New') {
      if (!selectedCategory || !selectedCategory.name.trim()) {
        return 'Please provide a new category name';
      }
    }
  
    return undefined;
  };

  // Validation for text areas
export const validateTextArea = (value: string, fieldName: string): string | undefined => {
  if (!value.trim()) {
    return `Please provide ${fieldName}`;
  }
  return undefined;
};