import React from 'react';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Box
} from '@mui/material';
import { Facebook, Twitter, LinkedIn } from '@mui/icons-material';

export const MuiBottomNavBar = () => {

    return (
        <AppBar position='static' style={{ marginTop: 'auto' , backgroundColor: '#5e2466'}}>
            <Toolbar>
                {/* Copyright */}
                <Typography color='inherit' fontSize={'0.7rem'}>
                    Copyright © MRI-Simmons, 2021
                </Typography>
                <Box sx={{ flexGrow: 1 }} />

                <Typography color='inherit' fontSize={'0.7rem'} style={{ flexGrow: 1 }}>
                    <a href="https://mrisimmons.com/privacy" style={{ textDecoration: 'none', color: 'inherit' }}>Privacy Policy</a>
                </Typography>

                <Typography color='inherit' fontSize={'0.7rem'} style={{ flexGrow: 1 }}>
                    <a href="https://mrisimmons.com/privacy" style={{ textDecoration: 'none', color: 'inherit' }}>Do Not Sell My Info</a>
                </Typography>

                <IconButton color="inherit" href="https://www.facebook.com/MRISimmons">
                    <Facebook />
                </IconButton>
                <IconButton color="inherit" href="https:/twitter.com/MRI_Simmons">
                    <Twitter />
                </IconButton>
                <IconButton color="inherit" href="https://www.linkedin.com/company/mri-simmons">
                    <LinkedIn />
                </IconButton>
            </Toolbar>
        </AppBar>
    );
};
export default MuiBottomNavBar;